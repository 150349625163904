@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'DM Sans';
    src: url('../fonts/DMSans-VariableFont_opsz,wght.ttf') format('truetype');
  }
  :root {
    --background: 0 0% 100%; /* white */
    --foreground: 227, 8%, 77%; /* light_grey */

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 223, 100%, 8%; /* midnight */
    --primary-foreground: 189, 37%, 93%; /* stone */
    --primary-foreground-disabled: 227, 8%, 77%; /* light_grey */
    --primary-hover: 223, 100%, 4%; /* midnight_hover */
    --primary-disabled: 216, 22%, 95%; /* surface_disabled */

    --secondary: 0, 0%, 100%; /* white */
    --secondary-foreground: 223, 100%, 8%; /* midnight */
    --secondary-foreground-disabled: 227, 8%, 77%; /* light_grey */
    --secondary-hover: 0, 0%, 98%; /* surface_hover */
    --secondary-disabled: 0, 0%, 100%; /* white */

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;
  }
}

@layer utilities {
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
}
